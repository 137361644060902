import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PosterContent = ({ postId }) => {
    const [projectData, setProjectData] = useState(true);
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [companyname, setCompanyname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    // const { postId } = props;    
    const [flag, setFlag] = useState(0);

    const getUsers = async (data) => {
        try {
            const response = await axios.get(
                `https://sociity.in/sociityApi/emp/read.php?search_query=${data}`
            );

            const projectSingle = response.data.result;
            setProjectData(projectSingle);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    console.log('Project record data is', projectData);

    useEffect(() => {
        getUsers(postId);
    }, [postId]);

    // const handleDownload = () => {
    //     console.log('test');
    //     const link = document.createElement('a');
    //     link.download = 'Example-PDF-File';
    //     link.href = 'assets/proposal_guidelines.pdf';
    //     link.click();
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (fname.length === 0) {
            setFlag(1)
        }
        if (lname.length === 0) {
            setFlag(1)
        }
        if (companyname.length === 0) {
            setFlag(1)
        }
        if (email.length === 0) {
            setFlag(1)
        }
        if (phone.length === 0) {
            setFlag(1)
        }
        if (message.length === 0) {
            setFlag(1)
        }
        else {
            const url = 'http://localhost/getintouch.php';
            let fData = new FormData();
            fData.append('fname', fname);
            fData.append('lname', lname);
            fData.append('companyname', companyname);
            fData.append('email', email);
            fData.append('phone', phone);
            fData.append('message', message);
            setFlag(0)

            axios.post(url, fData)
                .then(response => alert(response.data))
                .catch(error => alert(error));
            // handleDownload();
        }
    }
    return (
        <>
            <section className="posterSection01" >
                <div className="container posterContainer">
                    <div className="row" >
                        <div className="col-lg-6">
                            <div className="abThumb" >
                                <img src={projectData[0]?.posterimage} alt="poster-image" style={{padding: "5px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="abCon1p">
                                <div className="subTitlep" style={{textAlign: "center"}}>{projectData[0]?.title}</div>
                                <div class="contact_form contactPageForm">
                                    <h2 class="secTitle02" style={{ alignItems: "center" }}>Get in touch</h2>
                                    <div class="col-lg-12 col-md-12">
                                        {flag === 1 &&
                                            <div style={{ background: '#d32f2f', padding: '5px', color: '#fff'}}>
                                                Please fill the required fields
                                            </div>
                                        }
                                        <input class="required" type="text" name="fname" value={fname} onChange={(e) => setFname(e.target.value)} placeholder="First Name" />
                                        <input class="required" type="text" name="lname" value={lname} onChange={(e) => setLname(e.target.value)} placeholder="Last Name" />
                                        <input class="required" type="text" name="companyname" value={companyname} onChange={(e) => setCompanyname(e.target.value)} placeholder="Company Name" />
                                        <input class="required" type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Business Email Address" />
                                        <input class="required" type="number" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Phone Number" />
                                        <textarea class="required" name="message" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Any additional information you would like to share with us"></textarea>

                                        <div class="container">
                                            <button type="button" class="berpo_btn" name='send' value="SEND" onClick={handleSubmit} href='assets/proposal_guidelines.pdf' download="Example-PDF-document">
                                                <span class="bp-text">Request for detailed proposal</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default PosterContent;